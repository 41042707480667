import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AutomationContent.css';

const AutomationContent = () => {
  const navigate = useNavigate();

  const cards = [
    {
      id: 'web3-automation',
      title: 'Автоматизация в WEB3',
      description: 'Основные принципы и инструменты автоматизации в Web3 пространстве',
      icon: '🤖'
    },
    {
      id: 'automation-practice',
      title: 'Практика',
      description: 'Практические примеры и задания по автоматизации в Web3',
      icon: '⚡'
    }
  ];

  return (
    <div className="at-container">
      <div className="at-cards-grid">
        {cards.map((card, index) => (
          <div 
            key={card.id}
            className="at-topic-card"
            style={{ animationDelay: `${index * 0.1}s` }}
          >
            <div className="at-card-content">
              <div className="at-card-icon">
                <span>{card.icon}</span>
              </div>
              <h3 className="at-card-title">{card.title}</h3>
              <p className="at-card-description">{card.description}</p>
              <button 
                className="at-goto-button"
                onClick={() => navigate(`/learn/automation/${card.id}`)}
              >
                <span className="button-text">Перейти</span>
                <div className="button-glow"></div>
              </button>
            </div>
            <div className="at-card-particles">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="at-particle"></div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AutomationContent;
