import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaYoutube, FaVideo } from 'react-icons/fa';
import StarryBackground from '../../common/StarryBackground';
import './WhatIsCryptoPage.css';

const WhatIsCryptoPage = () => {
  const navigate = useNavigate();
  const [userCount] = useState(Math.floor(Math.random() * 3) + 1);
  const [stars, setStars] = useState([]);

  useEffect(() => {
    const generateStars = () => {
      const newStars = [];
      for (let i = 0; i < 100; i++) {
        newStars.push({
          id: i,
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          size: `${Math.random() * 3}px`,
          animationDelay: `${Math.random() * 3}s`
        });
      }
      setStars(newStars);
    };

    generateStars();
  }, []);

  const videos = [
    {
      platform: 'YouTube',
      title: 'Что такое криптовалюта',
      url: 'https://youtu.be/4Qw9V614yU8',
      icon: FaYoutube
    },
    {
      platform: 'Rutube',
      title: 'Основы криптовалют',
      url: 'https://rutube.ru/video/private/dba983ed628f9ae7cb745b0ed6034afc/?p=nPZumgpiCpXKk3j5XbOa6A',
      icon: FaVideo
    }
  ];

  return (
    <StarryBackground>
      {/* Звездное небо */}
      <div className="starry-night">
        <div className="nebula" />
        {stars.map((star) => (
          <div
            key={star.id}
            className="star"
            style={{
              left: star.left,
              top: star.top,
              width: star.size,
              height: star.size,
              animationDelay: star.animationDelay
            }}
          />
        ))}
      </div>

      {/* Кнопка "Назад" */}
      <button className="crypto-back-button" onClick={() => navigate('/learn/crypto-basics')}>
        <div className="crypto-button-glow" />
        <FaArrowLeft className="crypto-button-icon" />
        <span className="crypto-button-text">Назад</span>
      </button>

      {/* Счетчик пользователей */}
      <div className="user-counter">
        <div className="counter-content">
          <div className="online-indicator" />
          <span className="counter-text">{userCount} онлайн</span>
        </div>
      </div>

      {/* Основной контент */}
      <div className="crypto-content">
        <h1 className="crypto-title">Что такое криптовалюта</h1>
        
        {/* Видео секция */}
        <div className="video-grid">
          {videos.map((video, index) => (
            <div key={index} className="video-card">
              <div className="video-card-content">
                <video.icon className="video-platform-icon" />
                <h3>{video.title}</h3>
                <a
                  href={video.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="watch-button"
                >
                  <span>Смотреть на {video.platform}</span>
                </a>
              </div>
            </div>
          ))}
        </div>

        {/* Информационная секция */}
        <div className="info-section">
          <h2>Важные моменты</h2>
          <div className="info-grid">
            <div className="info-card">
              <div className="info-icon">💡</div>
              <h3>Цифровые деньги будущего</h3>
              <p>Новая форма денег, основанная на криптографии и блокчейн-технологии, обеспечивающая безопасные и быстрые транзакции без посредников</p>
            </div>
            <div className="info-card">
              <div className="info-icon">⛓️</div>
              <h3>Децентрализованная система</h3>
              <p>Работает на основе распределенного реестра, где каждая транзакция проверяется и записывается множеством независимых участников сети</p>
            </div>
          </div>
        </div>
      </div>

      {/* Копирайт */}
      <div className="copyright">
        <p>© 2024 WingsNodeTeam</p>
        <p className="copyright-details">Все права защищены</p>
      </div>
    </StarryBackground>
  );
};

export default WhatIsCryptoPage;
