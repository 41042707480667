import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NftMarketsPage.css';

const StarryNight = () => {
  React.useEffect(() => {
    const createStars = () => {
      const starryNight = document.querySelector('.starry-night');
      if (!starryNight) return;

      // Используем DocumentFragment для оптимизации DOM операций
      const fragment = document.createDocumentFragment();
      
      const nebula = document.createElement('div');
      nebula.className = 'nebula';
      fragment.appendChild(nebula);

      // Оптимизированное количество звезд
      for (let i = 0; i < 80; i++) {
        const star = document.createElement('div');
        star.className = 'star';
        
        star.style.left = `${Math.random() * 100}%`;
        star.style.top = `${Math.random() * 100}%`;
        
        const size = Math.random() * 3;
        star.style.width = `${size}px`;
        star.style.height = `${size}px`;
        
        star.style.animationDelay = `${Math.random() * 3}s`;
        
        fragment.appendChild(star);
      }

      starryNight.innerHTML = '';
      starryNight.appendChild(fragment);
    };

    // Добавляем debounce для обработчика resize
    let resizeTimeout;
    const handleResize = () => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
      resizeTimeout = setTimeout(createStars, 150);
    };

    createStars();
    window.addEventListener('resize', handleResize);

    return () => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <div className="starry-night" />;
};

const UserCounter = () => {
  const [userCount, setUserCount] = React.useState(0);

  React.useEffect(() => {
    const initialCount = Math.floor(Math.random() * 3) + 1;
    setUserCount(initialCount);

    const interval = setInterval(() => {
      const newCount = Math.floor(Math.random() * 3) + 1;
      setUserCount(newCount);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="user-counter">
      <div className="counter-content">
        <div className="online-indicator"></div>
        <span className="counter-text">Онлайн: {userCount}</span>
      </div>
    </div>
  );
};

const NftMarketsPage = () => {
  const navigate = useNavigate();

  // Предварительно вычисляем стили для анимации
  const getAnimationStyle = (index) => ({
    animationDelay: `${index * 0.1}s`
  });

  const cards = [
    {
      id: 'blur',
      title: 'Blur',
      description: 'Профессиональная NFT торговая платформа',
      icon: '🌀',
      externalLink: 'https://blur.io/'
    },
    {
      id: 'opensea',
      title: 'OpenSea',
      description: 'Крупнейший NFT маркетплейс',
      icon: '🌊',
      externalLink: 'https://opensea.io/'
    },
    {
      id: 'rarible',
      title: 'Rarible',
      description: 'Мультичейн NFT платформа',
      icon: '🎨',
      externalLink: 'https://rarible.com/'
    },
    {
      id: 'foundation',
      title: 'Foundation',
      description: 'Кураторская NFT платформа',
      icon: '🎭',
      externalLink: 'https://foundation.app/'
    },
    {
      id: 'superrare',
      title: 'SuperRare',
      description: 'Эксклюзивный NFT маркетплейс',
      icon: '💎',
      externalLink: 'https://superrare.com/'
    },
    {
      id: 'niftygateway',
      title: 'Nifty Gateway',
      description: 'Премиум NFT платформа',
      icon: '🖼️',
      externalLink: 'https://www.niftygateway.com/'
    },
    {
      id: 'magiceden',
      title: 'Magic Eden',
      description: 'Ведущий NFT маркетплейс на Solana',
      icon: '✨',
      externalLink: 'https://magiceden.io/'
    },
    {
      id: 'element',
      title: 'Element',
      description: 'Инновационный NFT маркетплейс',
      icon: '🌟',
      externalLink: 'https://element.market/'
    },
    {
      id: 'objkt',
      title: 'Objkt',
      description: 'NFT платформа на Tezos',
      icon: '🎯',
      externalLink: 'https://objkt.com/'
    },
    {
      id: 'gamma',
      title: 'Gamma',
      description: 'NFT маркетплейс на Stacks',
      icon: '🎮',
      externalLink: 'https://gamma.io/'
    }
  ];

  const handleCardClick = (card) => {
    if (card.externalLink) {
      window.open(card.externalLink, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="nft-markets-container">
      <StarryNight />
      
      <button className="back-button" onClick={() => navigate('/learn/useful-links')}>
        <div className="button-glow"></div>
        <span className="button-icon">←</span>
        <span className="button-text">Назад</span>
      </button>

      <UserCounter />

      <div className="topic-content">
        <h1 className="topic-title">NFT Маркеты</h1>
        <div className="nm-cards-grid">
          {cards.map((card, index) => (
            <div 
              key={card.id}
              className="nm-topic-card"
              style={getAnimationStyle(index)}
            >
              <div className="nm-card-content">
                <div className="nm-card-icon">
                  <span>{card.icon}</span>
                </div>
                <h3 className="nm-card-title">{card.title}</h3>
                <p className="nm-card-description">{card.description}</p>
                <button 
                  className="nm-goto-button"
                  onClick={() => handleCardClick(card)}
                >
                  <span className="button-text">Перейти</span>
                  <div className="button-glow"></div>
                </button>
              </div>
              <div className="nm-card-particles">
                {[...Array(5)].map((_, i) => (
                  <div key={i} className="nm-particle"></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NftMarketsPage;
