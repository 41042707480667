import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TradingContent.css';

const TradingContent = () => {
  const navigate = useNavigate();

  const cards = [
    {
      id: 'trading-basics',
      title: 'Что нужно знать о трейдинге',
      description: 'Основные концепции и принципы торговли на криптовалютном рынке',
      icon: '📈'
    },
    {
      id: 'trading-terms',
      title: 'Словарь трейдера',
      description: 'Основные термины и определения, используемые в трейдинге',
      icon: '📚',
      externalLink: 'https://teletype.in/@wingsnodeteam/slovar_treidera'
    }
  ];

  const handleCardClick = (card) => {
    if (card.externalLink) {
      window.open(card.externalLink, '_blank', 'noopener,noreferrer');
    } else {
      navigate(`/learn/trading/${card.id}`);
    }
  };

  return (
    <div className="tr-container">
      <div className="tr-cards-grid">
        {cards.map((card, index) => (
          <div 
            key={card.id}
            className="tr-topic-card"
            style={{ animationDelay: `${index * 0.1}s` }}
          >
            <div className="tr-card-content">
              <div className="tr-card-icon">
                <span>{card.icon}</span>
              </div>
              <h3 className="tr-card-title">{card.title}</h3>
              <p className="tr-card-description">{card.description}</p>
              <button 
                className="tr-goto-button"
                onClick={() => handleCardClick(card)}
              >
                <span className="button-text">Перейти</span>
                <div className="button-glow"></div>
              </button>
            </div>
            <div className="tr-card-particles">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="tr-particle"></div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TradingContent;
