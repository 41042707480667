import React from 'react';
import { useNavigate } from 'react-router-dom';
import './UsefulLinksContent.css';

const UsefulLinksContent = () => {
  const navigate = useNavigate();

  const cards = [
    {
      id: 'wallets',
      title: 'Криптовалютные кошельки',
      description: 'Обзор и ссылки на популярные криптовалютные кошельки',
      icon: '👛'
    },
    {
      id: 'exchanges',
      title: 'Биржи',
      description: 'Список надежных криптовалютных бирж',
      icon: '📊'
    },
    {
      id: 'bridges',
      title: 'Мосты',
      description: 'Проверенные bridge-сервисы для кросс-чейн переводов',
      icon: '🌉'
    },
    {
      id: 'exchangers',
      title: 'Обменники',
      description: 'Надежные сервисы для обмена криптовалют',
      icon: '💱'
    },
    {
      id: 'nft-markets',
      title: 'Маркеты NFT',
      description: 'Популярные площадки для торговли NFT',
      icon: '🎨'
    },
    {
      id: 'proxy',
      title: 'Аренда PROXY',
      description: 'Сервисы для аренды прокси-серверов',
      icon: '🔒'
    },
    {
      id: 'node-servers',
      title: 'Аренда серверов для нод',
      description: 'Хостинг-провайдеры для размещения нод',
      icon: '🖥️'
    }
  ];

  return (
    <div className="ul-container">
      <div className="ul-cards-grid">
        {cards.map((card, index) => (
          <div 
            key={card.id}
            className="ul-topic-card"
            style={{ animationDelay: `${index * 0.1}s` }}
          >
            <div className="ul-card-content">
              <div className="ul-card-icon">
                <span>{card.icon}</span>
              </div>
              <h3 className="ul-card-title">{card.title}</h3>
              <p className="ul-card-description">{card.description}</p>
              <button 
                className="ul-goto-button"
                onClick={() => navigate(`/learn/useful-links/${card.id}`)}
              >
                <span className="button-text">Перейти</span>
                <div className="button-glow"></div>
              </button>
            </div>
            <div className="ul-card-particles">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="ul-particle"></div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UsefulLinksContent;
