import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StarryBackground from '../../common/StarryBackground';
import './DexContent.css';

const DexContent = () => {
  const navigate = useNavigate();
  const [userCount] = useState(Math.floor(Math.random() * 3) + 1);
  const [stars, setStars] = useState([]);

  useEffect(() => {
    const generateStars = () => {
      const newStars = [];
      for (let i = 0; i < 100; i++) {
        newStars.push({
          id: i,
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          size: `${Math.random() * 3}px`,
          animationDelay: `${Math.random() * 3}s`
        });
      }
      setStars(newStars);
    };

    generateStars();
  }, []);

  const cards = [
    {
      id: 'uniswap',
      title: 'Uniswap',
      description: 'Ведущий DEX на Ethereum с автоматическим маркет-мейкером',
      icon: '🦄',
      externalLink: 'https://app.uniswap.org/'
    },
    {
      id: 'traderjoe',
      title: 'Trader Joe',
      description: 'Мультичейн DEX с инновационными торговыми функциями',
      icon: '🏪',
      externalLink: 'https://lfj.gg/avalanche/trade'
    },
    {
      id: 'curve',
      title: 'Curve',
      description: 'Эффективный обмен стейблкоинов и токенизированных активов',
      icon: '⚡',
      externalLink: 'https://curve.fi/#/ethereum/swap'
    },
    {
      id: 'sushi',
      title: 'SushiSwap',
      description: 'Децентрализованная биржа с широким набором DeFi сервисов',
      icon: '🍣',
      externalLink: 'https://www.sushi.com/ethereum/swap'
    },
    {
      id: 'balancer',
      title: 'Balancer',
      description: 'Гибкий протокол для управления ликвидностью',
      icon: '⚖️',
      externalLink: 'https://balancer.fi/'
    },
    {
      id: 'syncswap',
      title: 'SyncSwap',
      description: 'Современная DEX платформа с низкими комиссиями',
      icon: '🔄',
      externalLink: 'https://syncswap.xyz/'
    }
  ];

  const handleCardClick = (card) => {
    if (card.externalLink) {
      window.open(card.externalLink, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <StarryBackground>
      {/* Звездное небо */}
      <div className="starry-night">
        <div className="nebula" />
        {stars.map((star) => (
          <div
            key={star.id}
            className="star"
            style={{
              left: star.left,
              top: star.top,
              width: star.size,
              height: star.size,
              animationDelay: star.animationDelay
            }}
          />
        ))}
      </div>

      {/* Кнопка "Назад" */}
      <button className="dex-back-button" onClick={() => navigate('/learn/useful-links/exchanges')}>
        <div className="dex-button-glow" />
        <span className="dex-button-icon">←</span>
        <span className="dex-button-text">Назад</span>
      </button>

      {/* Счетчик пользователей */}
      <div className="user-counter">
        <div className="counter-content">
          <div className="online-indicator" />
          <span className="counter-text">{userCount} онлайн</span>
        </div>
      </div>

      {/* Основной контент */}
      <div className="dex-content">
        <h1 className="dex-title">DEX биржи</h1>
        <div className="dex-subtitle">Децентрализованные биржи для прямого обмена криптовалют</div>
        <div className="dex-cards-grid">
          {cards.map((card, index) => (
            <div 
              key={card.id}
              className="dex-topic-card"
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className="dex-card-content">
                <div className="dex-card-icon">
                  <span>{card.icon}</span>
                </div>
                <h3 className="dex-card-title">{card.title}</h3>
                <p className="dex-card-description">{card.description}</p>
                <button 
                  className="dex-goto-button"
                  onClick={() => handleCardClick(card)}
                >
                  <span className="button-text">Перейти</span>
                  <div className="button-glow"></div>
                </button>
              </div>
              <div className="dex-card-particles">
                {[...Array(5)].map((_, i) => (
                  <div key={i} className="dex-particle"></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Копирайт */}
      <div className="copyright">
        <p>© 2024 WingsNodeTeam</p>
        <p className="copyright-details">Все права защищены</p>
      </div>
    </StarryBackground>
  );
};

export default DexContent;
