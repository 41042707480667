import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BridgesPage.css';

// Компонент звездного неба
const StarryNight = () => {
  return (
    <div className="starry-night">
      {[...Array(40)].map((_, i) => (
        <div key={i} className="star" style={{
          top: `${Math.random() * 100}%`,
          left: `${Math.random() * 100}%`,
          animationDelay: `${Math.random() * 3}s`,
          width: `${Math.random() * 2 + 1}px`,
          height: `${Math.random() * 2 + 1}px`
        }} />
      ))}
      <div className="nebula"></div>
    </div>
  );
};

// Компонент счетчика пользователей
const UserCounter = () => {
  const [userCount, setUserCount] = React.useState(0);

  React.useEffect(() => {
    const initialCount = Math.floor(Math.random() * 3) + 1; // От 1 до 3
    setUserCount(initialCount);

    const interval = setInterval(() => {
      const newCount = Math.floor(Math.random() * 3) + 1; // От 1 до 3
      setUserCount(newCount);
    }, 60000); // Обновление каждую минуту

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="user-counter">
      <div className="counter-content">
        <div className="online-indicator"></div>
        <span className="counter-text">Онлайн: {userCount}</span>
      </div>
    </div>
  );
};

const BridgesPage = () => {
  const navigate = useNavigate();

  const cards = [
    {
      id: 'official',
      title: 'Официальные мосты',
      description: 'Проверенные мосты от официальных разработчиков блокчейнов',
      icon: '🌉'
    },
    {
      id: 'other',
      title: 'Прочие мосты',
      description: 'Альтернативные решения для кросс-чейн переводов',
      icon: '🔄'
    }
  ];

  return (
    <div className="bridges-page-container">
      <StarryNight />
      
      {/* Кнопка "Назад" */}
      <button className="back-button" onClick={() => navigate('/learn/useful-links')}>
        <div className="button-glow"></div>
        <span className="button-icon">←</span>
        <span className="button-text">Назад</span>
      </button>

      {/* Счетчик пользователей */}
      <UserCounter />

      {/* Контент страницы */}
      <div className="topic-content">
        <h1 className="topic-title">Мосты</h1>
        <div className="bp-cards-grid">
          {cards.map((card, index) => (
            <div 
              key={card.id}
              className="bp-topic-card"
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className="bp-card-content">
                <div className="bp-card-icon">
                  <span>{card.icon}</span>
                </div>
                <h3 className="bp-card-title">{card.title}</h3>
                <p className="bp-card-description">{card.description}</p>
                <button 
                  className="bp-goto-button"
                  onClick={() => navigate(`/learn/useful-links/bridges/${card.id}`)}
                >
                  <span className="button-text">Перейти</span>
                  <div className="button-glow"></div>
                </button>
              </div>
              <div className="bp-card-particles">
                {[...Array(5)].map((_, i) => (
                  <div key={i} className="bp-particle"></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BridgesPage;
