import React from 'react';
import { useNavigate } from 'react-router-dom';
import StarryBackground from '../common/StarryBackground';
import './SelfLearning.css';

const SelfLearning = () => {
  const navigate = useNavigate();

  const learningCards = [
    {
      id: 'getting-started',
      title: 'Начало работы',
      description: 'Основы работы с платформой',
      icon: '🚀',
      delay: '0s'
    },
    {
      id: 'crypto-basics',
      title: 'Основы криптовалюты',
      description: 'Базовые понятия и принципы работы',
      icon: '💰',
      delay: '0.1s'
    },
    {
      id: 'security-basics',
      title: 'Основы безопасности',
      description: 'Защита и безопасность в крипто',
      icon: '🔒',
      delay: '0.2s'
    },
    {
      id: 'terms',
      title: 'Словарь терминов',
      description: 'Основные термины и определения',
      icon: '📚',
      delay: '0.3s'
    },
    {
      id: 'nodes',
      title: 'Что такое ноды',
      description: 'Все о нодах и их работе',
      icon: '🌐',
      delay: '0.4s'
    },
    {
      id: 'trading',
      title: 'Трейдинг',
      description: 'Основы торговли криптовалютой',
      icon: '📈',
      delay: '0.5s'
    },
    {
      id: 'defi',
      title: 'DeFi',
      description: 'Децентрализованные финансы',
      icon: '⚡',
      delay: '0.6s'
    },
    {
      id: 'scaling',
      title: 'Масштабирование',
      description: 'Решения для масштабирования',
      icon: '🔄',
      delay: '0.7s'
    },
    {
      id: 'automation',
      title: 'Автоматизация',
      description: 'Автоматизация процессов',
      icon: '⚙️',
      delay: '0.8s'
    },
    {
      id: 'useful-links',
      title: 'Полезные ссылки',
      description: 'Подборка полезных ресурсов',
      icon: '🔗',
      delay: '0.9s'
    }
  ];

  return (
    <StarryBackground>
      {/* Кнопка назад */}
      <button className="back-button" onClick={() => navigate('/')}>
        <div className="button-glow"></div>
        <span className="button-icon">←</span>
        <span className="button-text">Назад</span>
      </button>

      {/* Заголовок */}
      <div className="header-container">
        <h1 className="page-title">Самообучение</h1>
        <p className="page-subtitle">Выберите тему для изучения</p>
      </div>

      {/* Сетка карточек */}
      <div className="cards-grid">
        {learningCards.map((card) => (
          <div
            key={card.id}
            className="learning-card"
            onClick={() => navigate(`/learn/${card.id}`)}
            style={{ animationDelay: card.delay }}
          >
            <div className="card-content">
              <div className="icon-wrapper">
                <span className="card-icon">{card.icon}</span>
              </div>
              <h3 className="card-title">{card.title}</h3>
              <p className="card-description">{card.description}</p>
              <button className="study-button">Изучить</button>
            </div>
          </div>
        ))}
      </div>

      {/* Копирайт */}
      <div className="copyright">
        <p>2024 WingsNodeTeam. Все права защищены.</p>
        <p className="copyright-details">Разработано с любовью для крипто-энтузиастов</p>
      </div>
    </StarryBackground>
  );
};

export default SelfLearning;
