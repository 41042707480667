import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import StarryBackground from '../components/StarryBackground';
import './NodesPracticePage.css';

const NodesPracticePage = () => {
  const navigate = useNavigate();
  const [userCount] = useState(Math.floor(Math.random() * 3) + 1);
  const [stars, setStars] = useState([]);

  useEffect(() => {
    const generateStars = () => {
      const newStars = [];
      for (let i = 0; i < 100; i++) {
        newStars.push({
          id: i,
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          size: `${Math.random() * 3}px`,
          animationDelay: `${Math.random() * 3}s`
        });
      }
      setStars(newStars);
    };

    generateStars();
  }, []);

  const cards = [
    {
      id: 'server-order',
      title: 'Где заказать сервер для ноды',
      description: 'Руководство по выбору и заказу сервера',
      icon: '🖥️',
      externalLink: 'https://youtu.be/7VImrSD3zEg'
    },
    {
      id: 'server-connect',
      title: 'Как подключиться к серверу',
      description: 'Пошаговая инструкция по подключению',
      icon: '🔌',
      externalLink: 'https://youtu.be/qJi7PPUGQI8'
    },
    {
      id: 'server-prep',
      title: 'Подготовка сервера к работе',
      description: 'Настройка сервера перед установкой ноды',
      icon: '⚙️',
      externalLink: 'https://youtu.be/yqjbpw5Lu5s'
    },
    {
      id: 'server-nav-1',
      title: 'Навигация на сервере Часть 1',
      description: 'Основы навигации в терминале',
      icon: '🗺️',
      externalLink: 'https://youtu.be/sXtHnMeSG7I'
    },
    {
      id: 'server-nav-2',
      title: 'Навигация на сервере Часть 2',
      description: 'Продвинутая навигация в терминале',
      icon: '📍',
      externalLink: 'https://youtu.be/aOYcJWMu5aM'
    },
    {
      id: 'server-screen',
      title: 'Навигация на сервере: Screen',
      description: 'Работа с утилитой Screen',
      icon: '💻',
      externalLink: 'https://youtu.be/iUPJxPtpWjw'
    },
    {
      id: 'server-docker',
      title: 'Навигация на сервере: Docker',
      description: 'Основы работы с Docker',
      icon: '🐳',
      externalLink: 'https://youtu.be/T6qMyh2vVDo'
    },
    {
      id: 'multiple-nodes',
      title: 'Несколько нод на одном сервере',
      description: 'Настройка нескольких нод',
      icon: '🔄',
      externalLink: 'https://youtu.be/y7Pv_yZAS0I'
    }
  ];

  const handleCardClick = (card) => {
    if (card.externalLink) {
      window.open(card.externalLink, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <StarryBackground>
      {/* Звездное небо */}
      <div className="starry-night">
        <div className="nebula" />
        {stars.map((star) => (
          <div
            key={star.id}
            className="star"
            style={{
              left: star.left,
              top: star.top,
              width: star.size,
              height: star.size,
              animationDelay: star.animationDelay
            }}
          />
        ))}
      </div>

      {/* Кнопка "Назад" */}
      <button className="stable-back-button" onClick={() => navigate('/learn/nodes')}>
        <div className="stable-button-glow" />
        <FaArrowLeft className="stable-button-icon" />
        <span className="stable-button-text">Назад</span>
      </button>

      {/* Счетчик пользователей */}
      <div className="user-counter">
        <div className="counter-content">
          <div className="online-indicator" />
          <span className="counter-text">{userCount} онлайн</span>
        </div>
      </div>

      {/* Основной контент */}
      <div className="sb-container">
        <div className="sb-cards-grid">
          {cards.map((card, index) => (
            <div 
              key={card.id}
              className="sb-topic-card"
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className="sb-card-content">
                <div className="sb-card-icon">
                  <span>{card.icon}</span>
                </div>
                <h3 className="sb-card-title">{card.title}</h3>
                <p className="sb-card-description">{card.description}</p>
                <button 
                  className="sb-goto-button"
                  onClick={() => handleCardClick(card)}
                >
                  <span className="button-text">Смотреть</span>
                  <div className="button-glow"></div>
                </button>
              </div>
              <div className="sb-card-particles">
                {[...Array(5)].map((_, i) => (
                  <div key={i} className="sb-particle"></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Копирайт */}
      <div className="copyright">
        <p>© 2024 WingsNodeTeam</p>
        <p className="copyright-details">Все права защищены</p>
      </div>
    </StarryBackground>
  );
};

export default NodesPracticePage;
