import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CryptoBasicsContent.css';

const CryptoBasicsContent = () => {
  const navigate = useNavigate();

  const cards = [
    {
      id: 'what-is-crypto',
      title: 'Что такое криптовалюта',
      description: 'Основные понятия и принципы работы криптовалют',
      icon: '₿'
    },
    {
      id: 'what-is-bitcoin',
      title: 'Что такое Биткоин',
      description: 'Первая и главная криптовалюта',
      icon: '🌟'
    },
    {
      id: 'coins-and-tokens',
      title: 'Что такое коин и токены',
      description: 'Различия между монетами и токенами',
      icon: '🪙'
    },
    {
      id: 'smart-contracts',
      title: 'Что такое смарт-контракты',
      description: 'Автоматизированные контракты в блокчейне',
      icon: '📜'
    },
    {
      id: 'why-crypto',
      title: 'Зачем нужна криптовалюта',
      description: 'Преимущества и применение криптовалют',
      icon: '🎯'
    },
    {
      id: 'stablecoins',
      title: 'Что такое стейблкоины',
      description: 'Стабильные криптовалюты и их роль',
      icon: '🔒'
    },
    {
      id: 'earning-approach',
      title: 'Подход к заработку на криптовалюте',
      description: 'Стратегии и методы заработка',
      icon: '📊'
    },
    {
      id: 'crypto-risks',
      title: 'Риски в криптовалюте',
      description: 'Основные риски и меры безопасности',
      icon: '⚠️'
    },
    {
      id: 'earning-methods-1',
      title: 'Способы заработка в криптовалюте Часть 1',
      description: 'Базовые методы заработка',
      icon: '💰'
    },
    {
      id: 'earning-methods-2',
      title: 'Способы заработка в криптовалюте Часть 2',
      description: 'Продвинутые методы заработка',
      icon: '📈'
    },
    {
      id: 'why-twitter',
      title: 'Зачем нужен Twitter',
      description: 'Роль Twitter в крипто-сообществе',
      icon: '🐦'
    }
  ];

  return (
    <div className="cb-container">
      <div className="cb-cards-grid">
        {cards.map((card, index) => (
          <div 
            key={card.id}
            className="cb-topic-card"
            style={{ animationDelay: `${index * 0.1}s` }}
          >
            <div className="cb-card-content">
              <div className="cb-card-icon">
                <span>{card.icon}</span>
              </div>
              <h3 className="cb-card-title">{card.title}</h3>
              <p className="cb-card-description">{card.description}</p>
              <button 
                className="cb-goto-button"
                onClick={() => navigate(`/learn/crypto-basics/${card.id}`)}
              >
                <span className="button-text">Перейти</span>
                <div className="button-glow"></div>
              </button>
            </div>
            <div className="cb-card-particles">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="cb-particle"></div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CryptoBasicsContent;
