import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './NodeServersPage.css';

// StarryNight Component - оптимизированная версия
const StarryNight = React.memo(() => {
  useEffect(() => {
    const createStars = () => {
      const starryNight = document.querySelector('.starry-night');
      if (!starryNight) return;

      // Очищаем предыдущие звезды только если их количество изменилось
      const currentStars = starryNight.querySelectorAll('.star');
      if (currentStars.length !== 40) {
        starryNight.innerHTML = '';
        
        const nebula = document.createElement('div');
        nebula.className = 'nebula';
        starryNight.appendChild(nebula);

        // Уменьшаем количество звезд для оптимизации
        for (let i = 0; i < 40; i++) {
          const star = document.createElement('div');
          star.className = 'star';
          
          star.style.left = `${Math.random() * 100}%`;
          star.style.top = `${Math.random() * 100}%`;
          
          const size = Math.random() * 3;
          star.style.width = `${size}px`;
          star.style.height = `${size}px`;
          
          star.style.animationDelay = `${Math.random() * 3}s`;
          
          starryNight.appendChild(star);
        }
      }
    };

    createStars();
    
    // Добавляем debounce для обработчика resize
    let resizeTimer;
    const handleResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(createStars, 100);
    };
    
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(resizeTimer);
    };
  }, []);

  return <div className="starry-night" />;
});

// UserCounter Component - оптимизированная версия
const UserCounter = React.memo(() => {
  const [userCount, setUserCount] = useState(() => Math.floor(Math.random() * 3) + 1);

  useEffect(() => {
    const interval = setInterval(() => {
      setUserCount(Math.floor(Math.random() * 3) + 1);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="user-counter">
      <div className="counter-content">
        <div className="online-indicator" />
        <span className="counter-text">Онлайн: {userCount}</span>
      </div>
    </div>
  );
});

const NodeServersPage = () => {
  const navigate = useNavigate();

  // Мемоизируем массив хостинг-провайдеров
  const hostingProviders = useMemo(() => [
    {
      title: 'AEZA',
      description: 'Надежный хостинг с защитой от DDoS атак и круглосуточной поддержкой.',
      icon: '🖥️',
      url: 'https://aeza.net/ru?ref=594466'
    },
    {
      title: 'PQ hosting',
      description: 'Быстрые SSD сервера с гибкими тарифами и высокой производительностью.',
      icon: '🚀',
      url: 'https://pq.hosting/?from=36405'
    },
    {
      title: 'Xorek cloud',
      description: 'Облачные решения для размещения нод с автоматическим масштабированием.',
      icon: '☁️',
      url: 'https://xorek.cloud/?from=11384'
    },
    {
      title: 'PowerVPS',
      description: 'Мощные выделенные серверы с оптимизированной конфигурацией для нод.',
      icon: '⚡',
      url: 'https://powervps.net/ru/?from=91820'
    },
    {
      title: 'Vdsina',
      description: 'Доступные VDS серверы с высокой отказоустойчивостью и стабильностью.',
      icon: '🌐',
      url: 'https://vdsina.ru/'
    },
    {
      title: 'Mevspace',
      description: 'Профессиональный хостинг с индивидуальным подходом к каждому клиенту.',
      icon: '🛡️',
      url: 'https://panel.mevspace.com/login'
    }
  ], []);

  // Мемоизируем обработчики
  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleHostingClick = useCallback((url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }, []);

  // Мемоизируем рендер карточки
  const renderCard = useCallback((provider, index) => (
    <div
      key={provider.title}
      className="node-card"
      style={{ animationDelay: `${index * 0.1}s` }}
    >
      <div className="node-card-content">
        <div className="node-card-icon">
          {provider.icon}
        </div>
        <h3 className="node-card-title">{provider.title}</h3>
        <p className="node-card-description">{provider.description}</p>
        <button
          className="node-goto-button"
          onClick={() => handleHostingClick(provider.url)}
        >
          Перейти
        </button>
      </div>
      <div className="node-card-particles">
        {[...Array(5)].map((_, i) => (
          <div key={i} className="node-particle" />
        ))}
      </div>
    </div>
  ), [handleHostingClick]);

  return (
    <div className="node-page-container">
      <StarryNight />
      <button className="node-back-button" onClick={handleBack}>
        <span className="node-button-icon">←</span>
        <span className="node-button-text">Назад</span>
        <div className="node-button-glow" />
      </button>
      <UserCounter />
      
      <div className="node-content">
        <h1 className="node-title">Хостинг для нод</h1>
        <div className="node-cards-grid">
          {hostingProviders.map((provider, index) => renderCard(provider, index))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(NodeServersPage);
