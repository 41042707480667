import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StarryBackground from '../../common/StarryBackground';
import './OfficialBridgesContent.css';

const OfficialBridgesContent = () => {
  const navigate = useNavigate();
  const [userCount] = useState(Math.floor(Math.random() * 3) + 1);
  const [stars, setStars] = useState([]);

  useEffect(() => {
    const generateStars = () => {
      const newStars = [];
      for (let i = 0; i < 100; i++) {
        newStars.push({
          id: i,
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          size: `${Math.random() * 3}px`,
          animationDelay: `${Math.random() * 3}s`
        });
      }
      setStars(newStars);
    };

    generateStars();
  }, []);

  const cards = [
    {
      id: 'arbitrum',
      title: 'Arbitrum',
      description: 'Официальный мост для сети Arbitrum с оптимистичными роллапами',
      icon: '🌉',
      externalLink: 'https://bridge.arbitrum.io/'
    },
    {
      id: 'mantle',
      title: 'Mantle',
      description: 'Безопасный мост для экосистемы Mantle Network',
      icon: '🌁',
      externalLink: 'https://app.mantle.xyz/bridge'
    },
    {
      id: 'optimism',
      title: 'Optimism',
      description: 'Быстрый мост для сети Optimism с низкими комиссиями',
      icon: '⚡',
      externalLink: 'https://app.optimism.io/bridge/deposit'
    },
    {
      id: 'zksync',
      title: 'zkSync',
      description: 'Мост с ZK-роллапами для быстрых транзакций',
      icon: '🔄',
      externalLink: 'https://portal.zksync.io/bridge'
    },
    {
      id: 'starkgate',
      title: 'StarkGate',
      description: 'Официальный мост для сети StarkNet',
      icon: '⭐',
      externalLink: 'https://starkgate.starknet.io/'
    },
    {
      id: 'starknet',
      title: 'StarkNet',
      description: 'Безопасный мост с ZK-доказательствами',
      icon: '🌠',
      externalLink: 'https://starkgate.starknet.io/'
    }
  ];

  const handleCardClick = (card) => {
    if (card.externalLink) {
      window.open(card.externalLink, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <StarryBackground>
      {/* Звездное небо */}
      <div className="starry-night">
        <div className="nebula" />
        {stars.map((star) => (
          <div
            key={star.id}
            className="star"
            style={{
              left: star.left,
              top: star.top,
              width: star.size,
              height: star.size,
              animationDelay: star.animationDelay
            }}
          />
        ))}
      </div>

      {/* Кнопка "Назад" */}
      <button className="ob-back-button" onClick={() => navigate('/learn/useful-links')}>
        <div className="ob-button-glow" />
        <span className="ob-button-icon">←</span>
        <span className="ob-button-text">Назад</span>
      </button>

      {/* Счетчик пользователей */}
      <div className="user-counter">
        <div className="counter-content">
          <div className="online-indicator" />
          <span className="counter-text">{userCount} онлайн</span>
        </div>
      </div>

      {/* Основной контент */}
      <div className="ob-content">
        <h1 className="ob-title">Официальные мосты</h1>
        <div className="ob-subtitle">Проверенные мосты от официальных разработчиков блокчейнов</div>
        <div className="ob-cards-grid">
          {cards.map((card, index) => (
            <div 
              key={card.id}
              className="ob-topic-card"
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className="ob-card-content">
                <div className="ob-card-icon">
                  <span>{card.icon}</span>
                </div>
                <h3 className="ob-card-title">{card.title}</h3>
                <p className="ob-card-description">{card.description}</p>
                <button 
                  className="ob-goto-button"
                  onClick={() => handleCardClick(card)}
                >
                  <span className="button-text">Перейти</span>
                  <div className="button-glow"></div>
                </button>
              </div>
              <div className="ob-card-particles">
                {[...Array(5)].map((_, i) => (
                  <div key={i} className="ob-particle"></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Копирайт */}
      <div className="copyright">
        <p>© 2024 WingsNodeTeam</p>
        <p className="copyright-details">Все права защищены</p>
      </div>
    </StarryBackground>
  );
};

export default OfficialBridgesContent;
