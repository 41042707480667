import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Landing from './components/layout/Landing';
import SelfLearning from './components/learning/SelfLearning';
import LearningTopic from './components/learning/LearningTopic';
import WalletsPage from './components/learning/pages/WalletsPage';
import ExchangesPage from './components/learning/pages/ExchangesPage';
import BridgesPage from './components/learning/pages/BridgesPage';
import ExchangersPage from './components/learning/pages/ExchangersPage';
import NftMarketsPage from './components/learning/pages/NftMarketsPage';
import ProxyServicesPage from './components/learning/pages/ProxyServicesPage';
import NodeServersPage from './components/learning/pages/NodeServersPage';
import CryptoWalletPage from './components/learning/pages/CryptoWalletPage';
import CreateWallet from './components/learning/tutorials/CreateWallet';
import BuyCryptoPage from './components/learning/pages/BuyCryptoPage';
import ExchangesInfoPage from './components/learning/pages/ExchangesInfoPage';
import CardPurchasePage from './components/learning/pages/CardPurchasePage';
import P2PPurchasePage from './components/learning/pages/P2PPurchasePage';
import SendCryptoPage from './components/learning/pages/SendCryptoPage';
import WalletSetupPage from './components/learning/pages/WalletSetupPage';
import PracticePage from './components/learning/pages/PracticePage';
import WhatIsBitcoinPage from './components/learning/pages/WhatIsBitcoinPage';
import CoinsAndTokensPage from './components/learning/pages/CoinsAndTokensPage';
import SmartContractsPage from './components/learning/pages/SmartContractsPage';
import WhyCryptoPage from './components/learning/pages/WhyCryptoPage';
import StablecoinsPage from './components/learning/pages/StablecoinsPage';
import WhatIsCryptoPage from './components/learning/pages/WhatIsCryptoPage';
import EarningApproachPage from './components/learning/pages/EarningApproachPage';
import CryptoRisksPage from './components/learning/pages/CryptoRisksPage';
import EarningMethodsOnePage from './components/learning/pages/EarningMethodsOnePage';
import EarningMethodsTwoPage from './components/learning/pages/EarningMethodsTwoPage';
import WhyTwitterPage from './components/learning/pages/WhyTwitterPage';
import WalletSecurityPage from './components/learning/pages/WalletSecurityPage';
import MnemonicPhrasePage from './components/learning/pages/MnemonicPhrasePage';
import ImportMnemonicPage from './components/learning/pages/ImportMnemonicPage';
import CryptoSecurityPage from './components/learning/pages/CryptoSecurityPage';
import GmailSecurityPage from './components/learning/pages/GmailSecurityPage';
import NodesBasicsPage from './components/learning/pages/NodesBasicsPage';
import TradingBasicsPage from './components/learning/pages/TradingBasicsPage';
import DefiIntroPage from './components/learning/pages/DefiIntroPage';
import DefiSecurityPage from './components/learning/pages/DefiSecurityPage';
import ScalingBasicsPage from './components/learning/pages/ScalingBasicsPage';
import WalletConnectionPage from './components/learning/pages/WalletConnectionPage';
import Web3AutomationPage from './components/learning/pages/Web3AutomationPage';
import TerminalGuidePage from './components/learning/pages/TerminalGuidePage';
import NodesPracticePage from './components/learning/pages/NodesPracticePage';
import AutomationPracticePage from './components/learning/pages/AutomationPracticePage';
import MobileWalletsContent from './components/learning/content/MobileWalletsContent';
import DesktopWalletsContent from './components/learning/content/DesktopWalletsContent';
import HardwareWalletsContent from './components/learning/content/HardwareWalletsContent';
import CryptoExchangesContent from './components/learning/content/CryptoExchangesContent';
import DexContent from './components/learning/content/DexContent';
import OfficialBridgesContent from './components/learning/content/OfficialBridgesContent';
import OtherBridgesContent from './components/learning/content/OtherBridgesContent';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/self-learning" element={<SelfLearning />} />
      <Route path="/learn/:topic" element={<LearningTopic />} />
      <Route path="/learn/useful-links/wallets" element={<WalletsPage />} />
      <Route path="/learn/useful-links/wallets/desktop" element={<DesktopWalletsContent />} />
      <Route path="/learn/useful-links/wallets/mobile" element={<MobileWalletsContent />} />
      <Route path="/learn/useful-links/wallets/hardware" element={<HardwareWalletsContent />} />
      <Route path="/learn/useful-links/exchanges" element={<ExchangesPage />} />
      <Route path="/learn/useful-links/exchanges/crypto-exchanges" element={<CryptoExchangesContent />} />
      <Route path="/learn/useful-links/exchanges/dex" element={<DexContent />} />
      <Route path="/learn/useful-links/bridges" element={<BridgesPage />} />
      <Route path="/learn/useful-links/bridges/official" element={<OfficialBridgesContent />} />
      <Route path="/learn/useful-links/bridges/other" element={<OtherBridgesContent />} />
      <Route path="/learn/useful-links/exchangers" element={<ExchangersPage />} />
      <Route path="/learn/useful-links/nft-markets" element={<NftMarketsPage />} />
      <Route path="/learn/useful-links/proxy" element={<ProxyServicesPage />} />
      <Route path="/learn/useful-links/node-servers" element={<NodeServersPage />} />
      <Route path="/learn/getting-started/crypto-wallet" element={<CryptoWalletPage />} />
      <Route path="/learn/getting-started/create-wallet" element={<CreateWallet />} />
      <Route path="/learn/getting-started/buy-crypto" element={<BuyCryptoPage />} />
      <Route path="/learn/getting-started/exchanges" element={<ExchangesInfoPage />} />
      <Route path="/learn/getting-started/card-purchase" element={<CardPurchasePage />} />
      <Route path="/learn/getting-started/p2p" element={<P2PPurchasePage />} />
      <Route path="/learn/getting-started/send-crypto" element={<SendCryptoPage />} />
      <Route path="/learn/getting-started/wallet-setup" element={<WalletSetupPage />} />
      <Route path="/learn/getting-started/practice" element={<PracticePage />} />
      <Route path="/learn/crypto-basics/what-is-bitcoin" element={<WhatIsBitcoinPage />} />
      <Route path="/learn/crypto-basics/coins-and-tokens" element={<CoinsAndTokensPage />} />
      <Route path="/learn/crypto-basics/smart-contracts" element={<SmartContractsPage />} />
      <Route path="/learn/crypto-basics/why-crypto" element={<WhyCryptoPage />} />
      <Route path="/learn/crypto-basics/stablecoins" element={<StablecoinsPage />} />
      <Route path="/learn/crypto-basics/what-is-crypto" element={<WhatIsCryptoPage />} />
      <Route path="/learn/crypto-basics/earning-approach" element={<EarningApproachPage />} />
      <Route path="/learn/crypto-basics/crypto-risks" element={<CryptoRisksPage />} />
      <Route path="/learn/crypto-basics/earning-methods-1" element={<EarningMethodsOnePage />} />
      <Route path="/learn/crypto-basics/earning-methods-2" element={<EarningMethodsTwoPage />} />
      <Route path="/learn/crypto-basics/why-twitter" element={<WhyTwitterPage />} />
      <Route path="/learn/security-basics/wallet-security" element={<WalletSecurityPage />} />
      <Route path="/learn/security-basics/mnemonic-phrase" element={<MnemonicPhrasePage />} />
      <Route path="/learn/security-basics/import-mnemonic" element={<ImportMnemonicPage />} />
      <Route path="/learn/security-basics/crypto-security" element={<CryptoSecurityPage />} />
      <Route path="/learn/security-basics/gmail-security" element={<GmailSecurityPage />} />
      <Route path="/learn/nodes/nodes-basics" element={<NodesBasicsPage />} />
      <Route path="/learn/nodes/trading-basics" element={<TradingBasicsPage />} />
      <Route path="/learn/nodes/terminal-guide" element={<TerminalGuidePage />} />
      <Route path="/learn/nodes/practice" element={<NodesPracticePage />} />
      <Route path="/learn/defi/defi-intro" element={<DefiIntroPage />} />
      <Route path="/learn/defi/defi-security" element={<DefiSecurityPage />} />
      <Route path="/learn/scaling/scaling-basics" element={<ScalingBasicsPage />} />
      <Route path="/learn/scaling/wallet-connection" element={<WalletConnectionPage />} />
      <Route path="/learn/automation/web3-automation" element={<Web3AutomationPage />} />
      <Route path="/learn/automation/automation-practice" element={<AutomationPracticePage />} />
    </Routes>
  );
};

export default App;
