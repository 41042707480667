import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WalletsPage.css';

// Компонент звездного неба
const StarryNight = () => {
  return (
    <div className="starry-night">
      {[...Array(40)].map((_, i) => (
        <div key={i} className="star" style={{
          top: `${Math.random() * 100}%`,
          left: `${Math.random() * 100}%`,
          animationDelay: `${Math.random() * 3}s`,
          width: `${Math.random() * 2 + 1}px`,
          height: `${Math.random() * 2 + 1}px`
        }} />
      ))}
      <div className="nebula"></div>
    </div>
  );
};

// Компонент счетчика пользователей
const UserCounter = () => {
  const [userCount, setUserCount] = React.useState(0);

  React.useEffect(() => {
    const initialCount = Math.floor(Math.random() * 3) + 1; // От 1 до 3
    setUserCount(initialCount);

    const interval = setInterval(() => {
      const newCount = Math.floor(Math.random() * 3) + 1; // От 1 до 3
      setUserCount(newCount);
    }, 60000); // Обновление каждую минуту

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="user-counter">
      <div className="counter-content">
        <div className="online-indicator"></div>
        <span className="counter-text">Онлайн: {userCount}</span>
      </div>
    </div>
  );
};

const WalletsPage = () => {
  const navigate = useNavigate();

  const cards = [
    {
      id: 'mobile',
      title: 'Мобильные',
      description: 'Криптокошельки для смартфонов и планшетов',
      icon: '📱'
    },
    {
      id: 'desktop',
      title: 'Десктопные',
      description: 'Кошельки для компьютеров и ноутбуков',
      icon: '💻'
    },
    {
      id: 'hardware',
      title: 'Аппаратные',
      description: 'Физические устройства для хранения криптовалют',
      icon: '🔐'
    }
  ];

  return (
    <div className="wallets-page-container">
      <StarryNight />
      
      {/* Кнопка "Назад" */}
      <button className="back-button" onClick={() => navigate('/learn/useful-links')}>
        <div className="button-glow"></div>
        <span className="button-icon">←</span>
        <span className="button-text">Назад</span>
      </button>

      {/* Счетчик пользователей */}
      <UserCounter />

      {/* Контент страницы */}
      <div className="topic-content">
        <h1 className="topic-title">Криптовалютные кошельки</h1>
        <div className="wp-cards-grid">
          {cards.map((card, index) => (
            <div 
              key={card.id}
              className="wp-topic-card"
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className="wp-card-content">
                <div className="wp-card-icon">
                  <span>{card.icon}</span>
                </div>
                <h3 className="wp-card-title">{card.title}</h3>
                <p className="wp-card-description">{card.description}</p>
                <button 
                  className="wp-goto-button"
                  onClick={() => navigate(`/learn/useful-links/wallets/${card.id}`)}
                >
                  <span className="button-text">Перейти</span>
                  <div className="button-glow"></div>
                </button>
              </div>
              <div className="wp-card-particles">
                {[...Array(5)].map((_, i) => (
                  <div key={i} className="wp-particle"></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WalletsPage;
