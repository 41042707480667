import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaYoutube, FaVideo } from 'react-icons/fa';
import StarryBackground from '../../common/StarryBackground';
import './StablecoinsPage.css'; // Используем те же стили

const MnemonicPhrasePage = () => {
  const navigate = useNavigate();
  const [userCount] = useState(Math.floor(Math.random() * 3) + 1);
  const [stars, setStars] = useState([]);

  useEffect(() => {
    const generateStars = () => {
      const newStars = [];
      for (let i = 0; i < 100; i++) {
        newStars.push({
          id: i,
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          size: `${Math.random() * 3}px`,
          animationDelay: `${Math.random() * 3}s`
        });
      }
      setStars(newStars);
    };

    generateStars();
  }, []);

  const videos = [
    {
      platform: 'YouTube',
      title: 'Мнемоническая фраза',
      url: 'https://youtu.be/o8szIQjHXqo',
      icon: FaYoutube
    },
    {
      platform: 'Rutube',
      title: 'Защита seed-фразы',
      url: 'https://rutube.ru/video/private/d138b8aae9d6cfc5a98a57357209c766/?p=ZUtsHy-iYPJmbzBtdlX-WQ',
      icon: FaVideo
    }
  ];

  return (
    <StarryBackground>
      {/* Звездное небо */}
      <div className="starry-night">
        <div className="nebula" />
        {stars.map((star) => (
          <div
            key={star.id}
            className="star"
            style={{
              left: star.left,
              top: star.top,
              width: star.size,
              height: star.size,
              animationDelay: star.animationDelay
            }}
          />
        ))}
      </div>

      {/* Кнопка "Назад" */}
      <button className="stable-back-button" onClick={() => navigate('/learn/security-basics')}>
        <div className="stable-button-glow" />
        <FaArrowLeft className="stable-button-icon" />
        <span className="stable-button-text">Назад</span>
      </button>

      {/* Счетчик пользователей */}
      <div className="user-counter">
        <div className="counter-content">
          <div className="online-indicator" />
          <span className="counter-text">{userCount} онлайн</span>
        </div>
      </div>

      {/* Основной контент */}
      <div className="stable-content">
        <h1 className="stable-title">Мнемоническая фраза</h1>
        
        {/* Видео секция */}
        <div className="video-grid">
          {videos.map((video, index) => (
            <div key={index} className="video-card">
              <div className="video-card-content">
                <video.icon className="video-platform-icon" />
                <h3>{video.title}</h3>
                <a
                  href={video.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="watch-button"
                >
                  <span>Смотреть на {video.platform}</span>
                </a>
              </div>
            </div>
          ))}
        </div>

        {/* Информационная секция */}
        <div className="info-section">
          <h2>Важные моменты</h2>
          <div className="info-grid">
            <div className="info-card">
              <div className="info-icon">🔑</div>
              <h3>Ключ к кошельку</h3>
              <p>Seed-фраза - это резервная копия вашего кошелька. С её помощью вы можете восстановить доступ к своим средствам на любом устройстве.</p>
            </div>
            <div className="info-card">
              <div className="info-icon">📝</div>
              <h3>Надёжное хранение</h3>
              <p>Записывайте фразу только на бумаге и храните в нескольких безопасных местах. Никогда не храните её в электронном виде.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Копирайт */}
      <div className="copyright">
        <p>© 2024 WingsNodeTeam</p>
        <p className="copyright-details">Все права защищены</p>
      </div>
    </StarryBackground>
  );
};

export default MnemonicPhrasePage;
