import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaVideo } from 'react-icons/fa';
import StarryBackground from '../../common/StarryBackground';
import './PracticePage.css';

const PracticePage = () => {
  const navigate = useNavigate();
  const [userCount] = useState(Math.floor(Math.random() * 3) + 1);
  const [stars, setStars] = useState([]);

  useEffect(() => {
    const generateStars = () => {
      const newStars = [];
      for (let i = 0; i < 100; i++) {
        newStars.push({
          id: i,
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          size: `${Math.random() * 3}px`,
          animationDelay: `${Math.random() * 3}s`
        });
      }
      setStars(newStars);
    };

    generateStars();
  }, []);

  const videos = [
    {
      title: 'Создание кошелька MetaMask',
      url: 'https://rutube.ru/video/3b02dddfe2ee2f82e82b410b55861f46/',
      description: 'Пошаговая инструкция по созданию кошелька'
    },
    {
      title: 'Настройка и безопасность',
      url: 'https://rutube.ru/video/063c27f0ca487a665851a4f23763322d/',
      description: 'Базовые настройки безопасности'
    },
    {
      title: 'Работа с токенами',
      url: 'https://rutube.ru/video/f879408910e7a22020262f8ec8f53940/',
      description: 'Управление токенами в кошельке'
    },
    {
      title: 'Подключение к DApps',
      url: 'https://rutube.ru/video/794df72166c87aa827edd41384c958df/',
      description: 'Взаимодействие с децентрализованными приложениями'
    },
    {
      title: 'Продвинутые функции',
      url: 'https://rutube.ru/video/fe8b92066f171fa659dc97ea2b8ccb74/',
      description: 'Расширенные возможности кошелька'
    }
  ];

  return (
    <StarryBackground>
      {/* Звездное небо */}
      <div className="starry-night">
        <div className="nebula" />
        {stars.map((star) => (
          <div
            key={star.id}
            className="star"
            style={{
              left: star.left,
              top: star.top,
              width: star.size,
              height: star.size,
              animationDelay: star.animationDelay
            }}
          />
        ))}
      </div>

      {/* Кнопка "Назад" */}
      <button className="practice-back-button" onClick={() => navigate('/learn/getting-started')}>
        <div className="practice-button-glow" />
        <FaArrowLeft className="practice-button-icon" />
        <span className="practice-button-text">Назад</span>
      </button>

      {/* Счетчик пользователей */}
      <div className="user-counter">
        <div className="counter-content">
          <div className="online-indicator" />
          <span className="counter-text">{userCount} онлайн</span>
        </div>
      </div>

      {/* Основной контент */}
      <div className="practice-content">
        <h1 className="practice-title">Практические уроки</h1>
        
        {/* Видео секция */}
        <div className="video-grid">
          {videos.map((video, index) => (
            <div key={index} className="video-card">
              <div className="video-card-content">
                <FaVideo className="video-platform-icon" />
                <h3>{video.title}</h3>
                <p className="video-description">{video.description}</p>
                <a
                  href={video.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="watch-button"
                >
                  <span>Смотреть на Rutube</span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Копирайт */}
      <div className="copyright">
        <p> 2024 WingsNodeTeam</p>
        <p className="copyright-details">Все права защищены</p>
      </div>
    </StarryBackground>
  );
};

export default PracticePage;
