import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TermsContent.css';

const TermsContent = () => {
  const navigate = useNavigate();

  const cards = [
    {
      id: 'dictionary',
      title: 'Изучить словарь',
      description: 'Открыть полный словарь криптовалютных терминов в браузере',
      icon: '📚',
      externalLink: 'https://teletype.in/@wingsnodeteam/slovar'
    }
  ];

  const handleCardClick = (card) => {
    if (card.externalLink) {
      window.open(card.externalLink, '_blank', 'noopener,noreferrer');
    } else {
      navigate(`/learn/terms/${card.id}`);
    }
  };

  return (
    <div className="tm-container">
      <div className="tm-cards-grid">
        {cards.map((card, index) => (
          <div 
            key={card.id}
            className="tm-topic-card"
            style={{ animationDelay: `${index * 0.1}s` }}
          >
            <div className="tm-card-content">
              <div className="tm-card-icon">
                <span>{card.icon}</span>
              </div>
              <h3 className="tm-card-title">{card.title}</h3>
              <p className="tm-card-description">{card.description}</p>
              <button 
                className="tm-goto-button"
                onClick={() => handleCardClick(card)}
              >
                <span className="button-text">Открыть</span>
                <div className="button-glow"></div>
              </button>
            </div>
            <div className="tm-card-particles">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="tm-particle"></div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsContent;
