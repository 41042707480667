import React from 'react';
import { useNavigate } from 'react-router-dom';
import './GettingStartedContent.css';

const GettingStartedContent = () => {
  const navigate = useNavigate();

  const cards = [
    {
      id: 'crypto-wallet',
      title: 'Что такое криптокошелек',
      description: 'Основы работы с криптокошельками',
      icon: '👛'
    },
    {
      id: 'create-wallet',
      title: 'Создание криптокошелька',
      description: 'Пошаговое руководство по созданию',
      icon: '🔐'
    },
    {
      id: 'buy-crypto',
      title: 'Как купить криптовалюту',
      description: '4 основных способа покупки',
      icon: '💰'
    },
    {
      id: 'exchanges',
      title: 'Покупка через обменники',
      description: 'Работа с криптообменниками',
      icon: '🔄'
    },
    {
      id: 'card-purchase',
      title: 'Покупка через банковскую карту',
      description: 'Прямая покупка с карты',
      icon: '💳'
    },
    {
      id: 'p2p',
      title: 'Покупка через P2P',
      description: 'Peer-to-peer обмен криптовалют',
      icon: '🤝'
    },
    {
      id: 'send-crypto',
      title: 'Как отправлять криптовалюту',
      description: 'Руководство по транзакциям',
      icon: '📤'
    },
    {
      id: 'wallet-setup',
      title: 'Правильная установка кошельков',
      description: 'Безопасная настройка кошелька',
      icon: '⚙️'
    },
    {
      id: 'practice',
      title: 'Практика',
      description: 'Практические задания',
      icon: '✏️'
    }
  ];

  return (
    <div className="sb-container">
      <div className="sb-cards-grid">
        {cards.map((card, index) => (
          <div 
            key={card.id}
            className="sb-topic-card"
            style={{ animationDelay: `${index * 0.1}s` }}
          >
            <div className="sb-card-content">
              <div className="sb-card-icon">
                <span>{card.icon}</span>
              </div>
              <h3 className="sb-card-title">{card.title}</h3>
              <p className="sb-card-description">{card.description}</p>
              <button 
                className="sb-goto-button"
                onClick={() => navigate(`/learn/getting-started/${card.id}`)}
              >
                <span className="button-text">Перейти</span>
                <div className="button-glow"></div>
              </button>
            </div>
            <div className="sb-card-particles">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="sb-particle"></div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GettingStartedContent;
