import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './LearningTopic.css';
import GettingStartedContent from './content/GettingStartedContent';
import CryptoBasicsContent from './content/CryptoBasicsContent';
import SecurityBasicsContent from './content/SecurityBasicsContent';
import NodesContent from './content/NodesContent';
import TradingContent from './content/TradingContent';
import DefiContent from './content/DefiContent';
import ScalingContent from './content/ScalingContent';
import AutomationContent from './content/AutomationContent';
import TermsContent from './content/TermsContent';
import UsefulLinksContent from './content/UsefulLinksContent';

// Компонент звездного неба (переиспользуем логику)
const StarryNight = () => {
  return (
    <div className="starry-night">
      {[...Array(40)].map((_, i) => (
        <div key={i} className="star" style={{
          top: `${Math.random() * 100}%`,
          left: `${Math.random() * 100}%`,
          animationDelay: `${Math.random() * 3}s`,
          width: `${Math.random() * 2 + 1}px`,
          height: `${Math.random() * 2 + 1}px`
        }} />
      ))}
      <div className="nebula"></div>
    </div>
  );
};

// Компонент счетчика пользователей
const UserCounter = () => {
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    const initialCount = Math.floor(Math.random() * 3) + 1; // От 1 до 3
    setUserCount(initialCount);

    const interval = setInterval(() => {
      const newCount = Math.floor(Math.random() * 3) + 1; // От 1 до 3
      setUserCount(newCount);
    }, 60000); // Обновление каждую минуту

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="user-counter">
      <div className="counter-content">
        <div className="online-indicator"></div>
        <span className="counter-text">Онлайн: {userCount}</span>
      </div>
    </div>
  );
};

const LearningTopic = () => {
  const { topic } = useParams();
  const navigate = useNavigate();

  // Заголовки для разных тем
  const topicTitles = {
    'getting-started': 'Начало работы',
    'crypto-basics': 'Основы криптовалюты',
    'security-basics': 'Основы безопасности',
    'terms': 'Словарь терминов',
    'nodes': 'Что такое ноды',
    'trading': 'Трейдинг',
    'defi': 'DeFi',
    'scaling': 'Масштабирование',
    'automation': 'Автоматизация',
    'useful-links': 'Полезные ссылки'
  };

  return (
    <div className="learning-topic-container">
      <StarryNight />
      
      {/* Кнопка "Назад" */}
      <button className="back-button" onClick={() => navigate('/self-learning')}>
        <div className="button-glow"></div>
        <span className="button-icon">←</span>
        <span className="button-text">Назад</span>
      </button>

      {/* Счетчик пользователей */}
      <UserCounter />

      {/* Контент темы */}
      <div className="topic-content">
        {topic === 'getting-started' ? (
          <GettingStartedContent />
        ) : topic === 'crypto-basics' ? (
          <CryptoBasicsContent />
        ) : topic === 'security-basics' ? (
          <SecurityBasicsContent />
        ) : topic === 'nodes' ? (
          <NodesContent />
        ) : topic === 'trading' ? (
          <TradingContent />
        ) : topic === 'defi' ? (
          <DefiContent />
        ) : topic === 'scaling' ? (
          <ScalingContent />
        ) : topic === 'automation' ? (
          <AutomationContent />
        ) : topic === 'terms' ? (
          <TermsContent />
        ) : topic === 'useful-links' ? (
          <UsefulLinksContent />
        ) : (
          <>
            <h1 className="topic-title">{topicTitles[topic] || 'Тема обучения'}</h1>
            <div className="topic-body">
              <p>Контент для темы: {topicTitles[topic]}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LearningTopic;
