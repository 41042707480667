import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StarryBackground from '../../common/StarryBackground';
import './CryptoExchangesContent.css';

const CryptoExchangesContent = () => {
  const navigate = useNavigate();
  const [userCount] = useState(Math.floor(Math.random() * 3) + 1);
  const [stars, setStars] = useState([]);

  useEffect(() => {
    const generateStars = () => {
      const newStars = [];
      for (let i = 0; i < 100; i++) {
        newStars.push({
          id: i,
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          size: `${Math.random() * 3}px`,
          animationDelay: `${Math.random() * 3}s`
        });
      }
      setStars(newStars);
    };

    generateStars();
  }, []);

  const cards = [
    {
      id: 'binance',
      title: 'Binance',
      description: 'Крупнейшая криптовалютная биржа в мире',
      icon: '🌟',
      externalLink: 'https://accounts.binance.com/ru/register?ref=58041813'
    },
    {
      id: 'bybit',
      title: 'Bybit',
      description: 'Ведущая платформа для торговли криптовалютой',
      icon: '💫',
      externalLink: 'https://www.bybit.com/en/register?ref=DooLK'
    },
    {
      id: 'bitget',
      title: 'Bitget',
      description: 'Инновационная биржа с передовыми технологиями',
      icon: '⚡',
      externalLink: 'https://www.bitgetapps.com/ru/referral/register?clacCode=M53FPUP5&from=%2Fru%2Fevents%2Freferral&source=events'
    },
    {
      id: 'mexc',
      title: 'MEXC',
      description: 'Глобальная платформа для торговли цифровыми активами',
      icon: '🌐',
      externalLink: 'https://www.mexc.com/ru-RU/register?inviteCode=mexc-1SWPe'
    },
    {
      id: 'bingx',
      title: 'BingX',
      description: 'Надежная платформа для криптотрейдинга',
      icon: '💎',
      externalLink: 'https://bingx.com/ru-ru/invite/WPYPRS/'
    }
  ];

  const handleCardClick = (card) => {
    if (card.externalLink) {
      window.open(card.externalLink, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <StarryBackground>
      {/* Звездное небо */}
      <div className="starry-night">
        <div className="nebula" />
        {stars.map((star) => (
          <div
            key={star.id}
            className="star"
            style={{
              left: star.left,
              top: star.top,
              width: star.size,
              height: star.size,
              animationDelay: star.animationDelay
            }}
          />
        ))}
      </div>

      {/* Кнопка "Назад" */}
      <button className="ce-back-button" onClick={() => navigate('/learn/useful-links/exchanges')}>
        <div className="ce-button-glow" />
        <span className="ce-button-icon">←</span>
        <span className="ce-button-text">Назад</span>
      </button>

      {/* Счетчик пользователей */}
      <div className="user-counter">
        <div className="counter-content">
          <div className="online-indicator" />
          <span className="counter-text">{userCount} онлайн</span>
        </div>
      </div>

      {/* Основной контент */}
      <div className="ce-content">
        <h1 className="ce-title">Криптовалютные биржи</h1>
        <div className="ce-subtitle">Централизованные биржи для торговли криптовалютами</div>
        <div className="ce-cards-grid">
          {cards.map((card, index) => (
            <div 
              key={card.id}
              className="ce-topic-card"
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className="ce-card-content">
                <div className="ce-card-icon">
                  <span>{card.icon}</span>
                </div>
                <h3 className="ce-card-title">{card.title}</h3>
                <p className="ce-card-description">{card.description}</p>
                <button 
                  className="ce-goto-button"
                  onClick={() => handleCardClick(card)}
                >
                  <span className="button-text">Перейти</span>
                  <div className="button-glow"></div>
                </button>
              </div>
              <div className="ce-card-particles">
                {[...Array(5)].map((_, i) => (
                  <div key={i} className="ce-particle"></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Копирайт */}
      <div className="copyright">
        <p>© 2024 WingsNodeTeam</p>
        <p className="copyright-details">Все права защищены</p>
      </div>
    </StarryBackground>
  );
};

export default CryptoExchangesContent;
