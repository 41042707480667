import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProxyServicesPage.css';

// StarryNight Component
const StarryNight = () => {
  useEffect(() => {
    const createStars = () => {
      const starryNight = document.querySelector('.starry-night');
      if (!starryNight) return;

      // Используем DocumentFragment для оптимизации DOM операций
      const fragment = document.createDocumentFragment();
      
      const nebula = document.createElement('div');
      nebula.className = 'nebula';
      fragment.appendChild(nebula);

      // Уменьшаем количество звезд для лучшей производительности
      for (let i = 0; i < 80; i++) {
        const star = document.createElement('div');
        star.className = 'star';
        
        star.style.left = `${Math.random() * 100}%`;
        star.style.top = `${Math.random() * 100}%`;
        
        const size = Math.random() * 3;
        star.style.width = `${size}px`;
        star.style.height = `${size}px`;
        
        star.style.animationDelay = `${Math.random() * 3}s`;
        
        fragment.appendChild(star);
      }

      starryNight.innerHTML = '';
      starryNight.appendChild(fragment);
    };

    // Добавляем debounce для обработчика resize
    let resizeTimeout;
    const handleResize = () => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
      resizeTimeout = setTimeout(createStars, 150);
    };

    createStars();
    window.addEventListener('resize', handleResize);

    return () => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <div className="starry-night" />;
};

// UserCounter Component
const UserCounter = () => {
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    const initialCount = Math.floor(Math.random() * 3) + 1;
    setUserCount(initialCount);

    const interval = setInterval(() => {
      const newCount = Math.floor(Math.random() * 3) + 1;
      setUserCount(newCount);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="user-counter">
      <div className="counter-content">
        <div className="online-indicator"></div>
        <span className="counter-text">Онлайн: {userCount}</span>
      </div>
    </div>
  );
};

const ProxyServicesPage = () => {
  const navigate = useNavigate();

  // Предварительно вычисляем стили для анимации
  const getAnimationStyle = (index) => ({
    animationDelay: `${index * 0.1}s`
  });

  const proxyServices = [
    {
      title: 'Proxy Market',
      description: 'Широкий выбор прокси серверов с гибкими тарифами. Поддержка IPv4 и IPv6.',
      icon: '🌐',
      url: 'https://proxy.market/?ref=pm00042204'
    },
    {
      title: 'Proxy6',
      description: 'Качественные прокси с автоматической доставкой. Быстрая техподдержка.',
      icon: '🛡️',
      url: 'https://proxy6.net/?r=687214'
    },
    {
      title: 'ProxyLine',
      description: 'Премиум мобильные и резидентские прокси с глобальным покрытием.',
      icon: '🔒',
      url: 'https://proxyline.net/?ref=352951'
    },
    {
      title: 'Proxys',
      description: 'Профессиональные прокси-решения для бизнеса и частных лиц.',
      icon: '🌍',
      url: 'https://proxys.io/ru?refid=218706'
    }
  ];

  const handleBack = () => {
    navigate(-1);
  };

  const handleProxyClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="proxy-page-container">
      <StarryNight />
      <button className="proxy-back-button" onClick={handleBack}>
        <span className="proxy-button-icon">←</span>
        <span className="proxy-button-text">Назад</span>
        <div className="proxy-button-glow" />
      </button>
      <UserCounter />
      
      <div className="proxy-content">
        <h1 className="proxy-title">Прокси Сервисы</h1>
        <div className="proxy-cards-grid">
          {proxyServices.map((service, index) => (
            <div
              key={service.title}
              className="proxy-card"
              style={getAnimationStyle(index)}
            >
              <div className="proxy-card-content">
                <div className="proxy-card-icon">
                  {service.icon}
                </div>
                <h3 className="proxy-card-title">{service.title}</h3>
                <p className="proxy-card-description">{service.description}</p>
                <button
                  className="proxy-goto-button"
                  onClick={() => handleProxyClick(service.url)}
                >
                  Перейти
                </button>
              </div>
              <div className="proxy-card-particles">
                {[...Array(5)].map((_, i) => (
                  <div key={i} className="proxy-particle" />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProxyServicesPage;
