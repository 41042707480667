import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StarryBackground from '../../common/StarryBackground';
import './HardwareWalletsContent.css';

const HardwareWalletsContent = () => {
  const navigate = useNavigate();
  const [userCount] = useState(Math.floor(Math.random() * 3) + 1);
  const [stars, setStars] = useState([]);

  useEffect(() => {
    const generateStars = () => {
      const newStars = [];
      for (let i = 0; i < 100; i++) {
        newStars.push({
          id: i,
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          size: `${Math.random() * 3}px`,
          animationDelay: `${Math.random() * 3}s`
        });
      }
      setStars(newStars);
    };

    generateStars();
  }, []);

  const cards = [
    {
      id: 'trezor',
      title: 'Trezor',
      description: 'Первый в мире аппаратный кошелек с открытым исходным кодом',
      icon: '🔒',
      externalLink: 'https://trezor.io/'
    },
    {
      id: 'keepkey',
      title: 'Keepkey',
      description: 'Безопасный аппаратный кошелек премиум-класса',
      icon: '🛡️',
      externalLink: 'https://keepkey.com/'
    },
    {
      id: 'foundation',
      title: 'Foundation',
      description: 'Инновационное решение для хранения криптоактивов',
      icon: '💎',
      externalLink: 'https://foundation.xyz/'
    },
    {
      id: 'ledger',
      title: 'Ledger',
      description: 'Ведущий производитель аппаратных кошельков',
      icon: '🔐',
      externalLink: 'https://shop.ledger.com/?r=1828b5e93489'
    }
  ];

  const handleCardClick = (card) => {
    if (card.externalLink) {
      window.open(card.externalLink, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <StarryBackground>
      {/* Звездное небо */}
      <div className="starry-night">
        <div className="nebula" />
        {stars.map((star) => (
          <div
            key={star.id}
            className="star"
            style={{
              left: star.left,
              top: star.top,
              width: star.size,
              height: star.size,
              animationDelay: star.animationDelay
            }}
          />
        ))}
      </div>

      {/* Кнопка "Назад" */}
      <button className="hw-back-button" onClick={() => navigate('/learn/useful-links/wallets')}>
        <div className="hw-button-glow" />
        <span className="hw-button-icon">←</span>
        <span className="hw-button-text">Назад</span>
      </button>

      {/* Счетчик пользователей */}
      <div className="user-counter">
        <div className="counter-content">
          <div className="online-indicator" />
          <span className="counter-text">{userCount} онлайн</span>
        </div>
      </div>

      {/* Основной контент */}
      <div className="hw-content">
        <h1 className="hw-title">Аппаратные кошельки</h1>
        <div className="hw-cards-grid">
          {cards.map((card, index) => (
            <div 
              key={card.id}
              className="hw-topic-card"
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className="hw-card-content">
                <div className="hw-card-icon">
                  <span>{card.icon}</span>
                </div>
                <h3 className="hw-card-title">{card.title}</h3>
                <p className="hw-card-description">{card.description}</p>
                <button 
                  className="hw-goto-button"
                  onClick={() => handleCardClick(card)}
                >
                  <span className="button-text">Перейти</span>
                  <div className="button-glow"></div>
                </button>
              </div>
              <div className="hw-card-particles">
                {[...Array(5)].map((_, i) => (
                  <div key={i} className="hw-particle"></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Копирайт */}
      <div className="copyright">
        <p>© 2024 WingsNodeTeam</p>
        <p className="copyright-details">Все права защищены</p>
      </div>
    </StarryBackground>
  );
};

export default HardwareWalletsContent;
