import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StarryBackground from '../../common/StarryBackground';
import './MobileWalletsContent.css';

const MobileWalletsContent = () => {
  const navigate = useNavigate();
  const [userCount] = useState(Math.floor(Math.random() * 3) + 1);
  const [stars, setStars] = useState([]);

  useEffect(() => {
    const generateStars = () => {
      const newStars = [];
      for (let i = 0; i < 100; i++) {
        newStars.push({
          id: i,
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          size: `${Math.random() * 3}px`,
          animationDelay: `${Math.random() * 3}s`
        });
      }
      setStars(newStars);
    };

    generateStars();
  }, []);

  const cards = [
    {
      id: 'metamask',
      title: 'MetaMask',
      description: 'Популярный криптокошелек с открытым исходным кодом',
      icon: '🦊',
      externalLink: 'https://metamask.io/ru/'
    },
    {
      id: 'trustwallet',
      title: 'TrustWallet',
      description: 'Надежный мобильный кошелек от Binance',
      icon: '🛡️',
      externalLink: 'https://trustwallet.com/ru'
    },
    {
      id: 'core',
      title: 'Core.App',
      description: 'Мультифункциональный криптокошелек',
      icon: '💎',
      externalLink: 'https://core.app/discover/'
    },
    {
      id: 'okx',
      title: 'OKX Wallet',
      description: 'Безопасный кошелек от биржи OKX',
      icon: '🔐',
      externalLink: 'https://www.okx.com/ru/join/76303343'
    },
    {
      id: 'rabby',
      title: 'RabbyWallet',
      description: 'Инновационный Web3 кошелек',
      icon: '🐰',
      externalLink: 'https://rabby.io/'
    }
  ];

  const handleCardClick = (card) => {
    if (card.externalLink) {
      window.open(card.externalLink, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <StarryBackground>
      {/* Звездное небо */}
      <div className="starry-night">
        <div className="nebula" />
        {stars.map((star) => (
          <div
            key={star.id}
            className="star"
            style={{
              left: star.left,
              top: star.top,
              width: star.size,
              height: star.size,
              animationDelay: star.animationDelay
            }}
          />
        ))}
      </div>

      {/* Кнопка "Назад" */}
      <button className="mw-back-button" onClick={() => navigate('/learn/useful-links/wallets')}>
        <div className="mw-button-glow" />
        <span className="mw-button-icon">←</span>
        <span className="mw-button-text">Назад</span>
      </button>

      {/* Счетчик пользователей */}
      <div className="user-counter">
        <div className="counter-content">
          <div className="online-indicator" />
          <span className="counter-text">{userCount} онлайн</span>
        </div>
      </div>

      {/* Основной контент */}
      <div className="mw-content">
        <h1 className="mw-title">Мобильные кошельки</h1>
        <div className="mw-cards-grid">
          {cards.map((card, index) => (
            <div 
              key={card.id}
              className="mw-topic-card"
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className="mw-card-content">
                <div className="mw-card-icon">
                  <span>{card.icon}</span>
                </div>
                <h3 className="mw-card-title">{card.title}</h3>
                <p className="mw-card-description">{card.description}</p>
                <button 
                  className="mw-goto-button"
                  onClick={() => handleCardClick(card)}
                >
                  <span className="button-text">Перейти</span>
                  <div className="button-glow"></div>
                </button>
              </div>
              <div className="mw-card-particles">
                {[...Array(5)].map((_, i) => (
                  <div key={i} className="mw-particle"></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Копирайт */}
      <div className="copyright">
        <p>© 2024 WingsNodeTeam</p>
        <p className="copyright-details">Все права защищены</p>
      </div>
    </StarryBackground>
  );
};

export default MobileWalletsContent;
