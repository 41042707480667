import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StarryBackground from '../../common/StarryBackground';
import './OtherBridgesContent.css';

const OtherBridgesContent = () => {
  const navigate = useNavigate();
  const [userCount] = useState(Math.floor(Math.random() * 3) + 1);
  const [stars, setStars] = useState([]);

  useEffect(() => {
    const generateStars = () => {
      const newStars = [];
      for (let i = 0; i < 100; i++) {
        newStars.push({
          id: i,
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          size: `${Math.random() * 3}px`,
          animationDelay: `${Math.random() * 3}s`
        });
      }
      setStars(newStars);
    };

    generateStars();
  }, []);

  const cards = [
    {
      id: 'bungee',
      title: 'Bungee',
      description: 'Универсальный мост для быстрого и безопасного обмена между сетями',
      icon: '🌈',
      externalLink: 'https://www.bungee.exchange/refuel'
    },
    {
      id: 'stargate',
      title: 'Stargate',
      description: 'Надежный мост с поддержкой множества блокчейнов',
      icon: '🌟',
      externalLink: 'https://starkgate.starknet.io/bridge/deposit'
    },
    {
      id: 'merkly',
      title: 'Merkly',
      description: 'Инновационный мост для кросс-чейн транзакций',
      icon: '🔄',
      externalLink: 'https://minter.merkly.com/'
    },
    {
      id: 'orbiter',
      title: 'Orbiter',
      description: 'Быстрые и экономичные переводы между сетями',
      icon: '🛸',
      externalLink: 'https://www.orbiter.finance/en'
    },
    {
      id: 'portalbridge',
      title: 'Portalbridge',
      description: 'Универсальное решение для межсетевых переводов',
      icon: '🌀',
      externalLink: 'https://portalbridge.com/'
    },
    {
      id: 'rhino',
      title: 'Rhino',
      description: 'Эффективный мост для работы с сетью Scroll',
      icon: '🦏',
      externalLink: 'https://app.rhino.fi/bridge?chain=SCROLL'
    }
  ];

  const handleCardClick = (card) => {
    if (card.externalLink) {
      window.open(card.externalLink, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <StarryBackground>
      {/* Звездное небо */}
      <div className="starry-night">
        <div className="nebula" />
        {stars.map((star) => (
          <div
            key={star.id}
            className="star"
            style={{
              left: star.left,
              top: star.top,
              width: star.size,
              height: star.size,
              animationDelay: star.animationDelay
            }}
          />
        ))}
      </div>

      {/* Кнопка "Назад" */}
      <button className="other-back-button" onClick={() => navigate('/learn/useful-links/bridges')}>
        <div className="other-button-glow" />
        <span className="other-button-icon">←</span>
        <span className="other-button-text">Назад</span>
      </button>

      {/* Счетчик пользователей */}
      <div className="user-counter">
        <div className="counter-content">
          <div className="online-indicator" />
          <span className="counter-text">{userCount} онлайн</span>
        </div>
      </div>

      {/* Основной контент */}
      <div className="other-content">
        <h1 className="other-title">Прочие мосты</h1>
        <div className="other-subtitle">Альтернативные решения для кросс-чейн переводов</div>
        <div className="other-cards-grid">
          {cards.map((card, index) => (
            <div 
              key={card.id}
              className="other-topic-card"
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className="other-card-content">
                <div className="other-card-icon">
                  <span>{card.icon}</span>
                </div>
                <h3 className="other-card-title">{card.title}</h3>
                <p className="other-card-description">{card.description}</p>
                <button 
                  className="other-goto-button"
                  onClick={() => handleCardClick(card)}
                >
                  <span className="button-text">Перейти</span>
                  <div className="button-glow"></div>
                </button>
              </div>
              <div className="other-card-particles">
                {[...Array(5)].map((_, i) => (
                  <div key={i} className="other-particle"></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Копирайт */}
      <div className="copyright">
        <p>© 2024 WingsNodeTeam</p>
        <p className="copyright-details">Все права защищены</p>
      </div>
    </StarryBackground>
  );
};

export default OtherBridgesContent;
