import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import './Landing.css';

const GradientTitle = styled('h1')({
  fontSize: '4rem',
  textAlign: 'center',
  margin: '2rem 0',
  backgroundImage: 'linear-gradient(90deg, #FF69B4 0%, #DA70D6 25%, #9370DB 50%, #6A5ACD 75%, #FF69B4 100%)',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: 'softGradient 3s ease infinite',
  backgroundSize: '300% auto',
  fontWeight: 'bold',
  textShadow: '0 0 10px rgba(255, 105, 180, 0.2), 0 0 20px rgba(218, 112, 214, 0.1)',
  '@keyframes softGradient': {
    '0%': {
      backgroundPosition: '0% 50%'
    },
    '50%': {
      backgroundPosition: '100% 50%'
    },
    '100%': {
      backgroundPosition: '0% 50%'
    }
  }
});

const GradientText = styled('span')({
  backgroundImage: 'linear-gradient(90deg, #FF1493 0%, #FF00FF 20%, #9400D3 40%, #4B0082 60%, #0000FF 80%, #FF1493 100%)',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: 'gradient 2s linear infinite',
  backgroundSize: '400% auto',
  fontWeight: 'bold',
  textShadow: '0 0 15px rgba(255, 20, 147, 0.4), 0 0 25px rgba(148, 0, 211, 0.3)',
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%'
    },
    '100%': {
      backgroundPosition: '400% 50%'
    }
  }
});

const AcademyTitle = styled('span')({
  fontSize: '4rem',
  backgroundImage: 'linear-gradient(90deg, #FFB6C1 0%, #FFD700 20%, #98FB98 40%, #87CEEB 60%, #DDA0DD 80%, #FFB6C1 100%)',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: 'academyGradient 4s ease infinite',
  backgroundSize: '300% auto',
  fontWeight: 'bold',
  textShadow: '0 0 15px rgba(255, 182, 193, 0.3), 0 0 25px rgba(255, 215, 0, 0.2)',
  '@keyframes academyGradient': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' }
  }
});

const StarryNight = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(to bottom, #000000 0%, #000033 100%)',
        zIndex: 1
      }}
    >
      <div className="starry-night">
        {[...Array(40)].map((_, i) => (
          <div key={i} className="star" />
        ))}
      </div>
      <div className="meteors-container">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="meteor" />
        ))}
      </div>
    </Box>
  );
};

const UserCounter = () => {
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    const initialCount = Math.floor(Math.random() * (10 - 3 + 1)) + 3;
    setUserCount(initialCount);

    const interval = setInterval(() => {
      const change = Math.random() < 0.5 ? -1 : 1;
      setUserCount(prev => {
        const newCount = prev + change;
        if (newCount < 3) return 3;
        if (newCount > 10) return 10;
        return newCount;
      });
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="user-counter">
      <div className="counter-content">
        <div className="online-indicator"></div>
        <span className="counter-text">Онлайн: {userCount}</span>
      </div>
    </div>
  );
};

const Landing = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const nav = document.querySelector('.MuiAppBar-root');
    if (nav) nav.style.display = 'none';
    return () => {
      if (nav) nav.style.display = '';
    };
  }, []);

  return (
    <Box sx={{ 
      minHeight: '100vh',
      position: 'relative',
      backgroundColor: 'transparent'
    }}>
      <StarryNight />
      
      <Box sx={{ position: 'relative', zIndex: 2 }}>
        <div className="stars">
          <div className="star-small"></div>
          <div className="star-medium"></div>
          <div className="star-big"></div>
          <div className="star-tiny"></div>
          <div className="star-dust"></div>
        </div>
        <div className="milky-way"></div>
        
        <div className="sponsor-animation">
          <div className="ufo-container">
            <svg className="ufo" viewBox="0 0 100 200" width="120" height="240">
              <defs>
                <linearGradient id="beam-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" style={{stopColor: 'rgba(100, 200, 255, 0.8)', stopOpacity: 0.8}} />
                  <stop offset="50%" style={{stopColor: 'rgba(100, 200, 255, 0.3)', stopOpacity: 0.5}} />
                  <stop offset="100%" style={{stopColor: 'rgba(100, 200, 255, 0.1)', stopOpacity: 0.3}} />
                </linearGradient>
                
                <filter id="beam-blur">
                  <feGaussianBlur stdDeviation="1.5" />
                  <feColorMatrix type="matrix"
                    values="1 0 0 0 0
                            0 1 0 0 0
                            0 0 1 0 0
                            0 0 0 3 -1"/>
                </filter>
                
                <radialGradient id="beam-glow" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                  <stop offset="0%" style={{stopColor: 'rgba(255, 255, 255, 0.3)', stopOpacity: 0.8}}>
                    <animate attributeName="stop-opacity"
                      values="0.8;0.3;0.8"
                      dur="4s"
                      repeatCount="indefinite" />
                  </stop>
                  <stop offset="100%" style={{stopColor: 'rgba(100, 200, 255, 0)', stopOpacity: 0}} />
                </radialGradient>
              </defs>
              
              <ellipse className="ufo-cabin" cx="50" cy="20" rx="15" ry="10" fill="rgba(200, 230, 255, 0.9)" />
              <ellipse className="ufo-body" cx="50" cy="25" rx="30" ry="8" fill="rgba(150, 180, 255, 0.8)" />
              <circle className="ufo-light" cx="35" cy="25" r="2" fill="#fff" />
              <circle className="ufo-light" cx="50" cy="27" r="2" fill="#fff" />
              <circle className="ufo-light" cx="65" cy="25" r="2" fill="#fff" />
              
              <path className="tractor-beam main-beam" 
                    d="M50 33 L20 200 L80 200 Z" 
                    fill="url(#beam-gradient)"
                    filter="url(#beam-blur)" />
              
              <path className="tractor-beam glow-beam" 
                    d="M50 33 L30 200 L70 200 Z" 
                    fill="url(#beam-glow)"
                    opacity="0.5" />
              
              <g className="beam-particles">
                <circle className="particle" cx="50" cy="50" r="0.5" fill="white" />
                <circle className="particle" cx="45" cy="80" r="0.5" fill="white" />
                <circle className="particle" cx="55" cy="120" r="0.5" fill="white" />
                <circle className="particle" cx="48" cy="160" r="0.5" fill="white" />
                <circle className="particle" cx="52" cy="190" r="0.5" fill="white" />
              </g>
            </svg>
            <div className="sponsor-logo">
              <img src="/sponsor-logo.png" alt="Sponsor Logo" />
              <div className="hologram-container">
                <div className="hologram-text">
                  <div className="scan-line"></div>
                  <div className="text-content">
                    При поддержке<br/>
                    АНО "Центр развития<br/>
                    цифровых блокчейн инноваций<br/>
                    и информационных технологий"
                  </div>
                  <div className="glitch-effect"></div>
                </div>
              </div>
            </div>
        </div>
      </div>

      <div className="comet"></div>
      <div className="comet"></div>
      <div className="comet"></div>
      <div className="comet"></div>
      <div className="comet"></div>

      <div className="landing-container">
        <div className="logo-container">
          <img src="/logo192.png" alt="WingsNodeTeam Logo" />
        </div>
        
        <GradientTitle><AcademyTitle>Академия</AcademyTitle> WingsNodeTeam</GradientTitle>
        <p className="description">
          Профессиональное обучение от <GradientText>WingsNodeTeam</GradientText>
        </p>

        <div className="feature-cards">
          <div className="feature-card">
            <h3>Начни с основ</h3>
            <p>Бесплатные видеоуроки для самостоятельного изучения основ Web3 и блокчейн-технологий</p>
            <button className="animated-button start-learning" onClick={() => navigate('/self-learning')}>
              <span className="text-cycle">
                <span>Это бесплатно</span>
                <span>Начать обучение</span>
                <span>Смотреть уроки</span>
                <span>Учиться бесплатно</span>
                <span>Присоединиться</span>
              </span>
            </button>
          </div>

          <div className="feature-card">
            <h3>Профессиональный курс</h3>
            <p>Структурированная программа обучения с опытными специалистами WingsNodeTeam</p>
            <button className="animated-button pro-course" onClick={() => navigate('/course')}>
              <span className="text-cycle">
                <span>Узнать о курсе</span>
                <span>Когда старт?</span>
                <span>Как записаться?</span>
                <span>Сколько стоит?</span>
                <span>Узнать подробнее</span>
              </span>
              <div className="button-effects"></div>
            </button>
          </div>

          <div className="feature-card">
            <h3>Комьюнити</h3>
            <p>Присоединяйтесь к сообществу единомышленников в Telegram и следите за обновлениями на YouTube</p>
            <div className="social-buttons">
              <button onClick={() => window.open('https://t.me/WingsNodeTeam', '_blank')} className="animated-button telegram">
                <i className="fab fa-telegram"></i>
                Telegram
              </button>
              <button onClick={() => window.open('https://www.youtube.com/@WingsNodeTeam', '_blank')} className="animated-button youtube">
                <i className="fab fa-youtube"></i>
                YouTube
              </button>
              <button onClick={() => window.open('https://t.me/WingsNodeTeam_Book_bot', '_blank')} className="animated-button private-group">
                <i className="fas fa-users"></i>
                Закрытая группа
              </button>
            </div>
          </div>
        </div>

        <UserCounter />
        <div className="copyright">
          {new Date().getFullYear()} WingsNodeTeam. Все права защищены.
        </div>
      </div>
    </Box>
  </Box>
  );
};

export default Landing;
