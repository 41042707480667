import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ExchangersPage.css';

const StarryNight = () => {
  React.useEffect(() => {
    const createStars = () => {
      const starryNight = document.querySelector('.starry-night');
      if (!starryNight) return;

      // Используем DocumentFragment для оптимизации DOM операций
      const fragment = document.createDocumentFragment();
      
      const nebula = document.createElement('div');
      nebula.className = 'nebula';
      fragment.appendChild(nebula);

      // Оптимизированное количество звезд
      for (let i = 0; i < 80; i++) {
        const star = document.createElement('div');
        star.className = 'star';
        
        star.style.left = `${Math.random() * 100}%`;
        star.style.top = `${Math.random() * 100}%`;
        
        const size = Math.random() * 3;
        star.style.width = `${size}px`;
        star.style.height = `${size}px`;
        
        star.style.animationDelay = `${Math.random() * 3}s`;
        
        fragment.appendChild(star);
      }

      starryNight.innerHTML = '';
      starryNight.appendChild(fragment);
    };

    // Добавляем debounce для обработчика resize
    let resizeTimeout;
    const handleResize = () => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
      resizeTimeout = setTimeout(createStars, 150);
    };

    createStars();
    window.addEventListener('resize', handleResize);

    return () => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <div className="starry-night" />;
};

const UserCounter = () => {
  const [userCount, setUserCount] = React.useState(0);

  React.useEffect(() => {
    const initialCount = Math.floor(Math.random() * 3) + 1;
    setUserCount(initialCount);

    const interval = setInterval(() => {
      const newCount = Math.floor(Math.random() * 3) + 1;
      setUserCount(newCount);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="user-counter">
      <div className="counter-content">
        <div className="online-indicator"></div>
        <span className="counter-text">Онлайн: {userCount}</span>
      </div>
    </div>
  );
};

const ExchangersPage = () => {
  const navigate = useNavigate();

  // Предварительно вычисляем стили для анимации
  const getAnimationStyle = (index) => ({
    animationDelay: `${index * 0.1}s`
  });

  const cards = [
    {
      id: 'uniswap',
      title: 'Uniswap',
      description: 'Ведущий децентрализованный протокол обмена на Ethereum',
      icon: '🦄',
      externalLink: 'https://app.uniswap.org/'
    },
    {
      id: 'sushi',
      title: 'Sushi',
      description: 'Мультичейн DEX с широким набором DeFi-инструментов',
      icon: '🍣',
      externalLink: 'https://www.sushi.com/ethereum/swap'
    },
    {
      id: '1inch',
      title: '1inch',
      description: 'Агрегатор DEX с оптимизацией маршрутов обмена',
      icon: '🔄',
      externalLink: 'https://app.1inch.io/'
    },
    {
      id: 'pancakeswap',
      title: 'PancakeSwap',
      description: 'Популярный DEX на BNB Chain с геймификацией',
      icon: '🥞',
      externalLink: 'https://pancakeswap.finance/'
    },
    {
      id: 'mav',
      title: 'Mav',
      description: 'Инновационный протокол обмена с уникальными функциями',
      icon: '🚀',
      externalLink: 'https://app.mav.xyz/?chain=1'
    },
    {
      id: 'curve',
      title: 'Curve',
      description: 'DEX, специализирующийся на стейблкоинах',
      icon: '⚡',
      externalLink: 'https://curve.fi/#/ethereum/swap'
    },
    {
      id: 'aevo',
      title: 'Aevo',
      description: 'Современная платформа для торговли деривативами',
      icon: '📊',
      externalLink: 'https://app.aevo.xyz/perpetual/eth'
    },
    {
      id: 'defillama',
      title: 'DefiLlama',
      description: 'Агрегатор DeFi-данных и инструмент аналитики',
      icon: '🦙',
      externalLink: 'https://swap.defillama.com/?chain=ethereum&from=0x0000000000000000000000000000000000000000&tab=swap'
    },
    {
      id: 'dydx',
      title: 'dYdX',
      description: 'Децентрализованная платформа для маржинальной торговли',
      icon: '📈',
      externalLink: 'https://dydx.trade/trade/ETH-USD'
    },
    {
      id: 'syncswap',
      title: 'SyncSwap',
      description: 'Эффективный DEX с оптимизированными свопами',
      icon: '🔄',
      externalLink: 'https://syncswap.xyz/'
    },
    {
      id: 'izumi',
      title: 'Izumi',
      description: 'Инновационная платформа для DeFi-операций',
      icon: '🌸',
      externalLink: 'https://izumi.finance/trade/swap'
    },
    {
      id: 'myswap',
      title: 'MySwap',
      description: 'Простой и удобный интерфейс для обмена криптовалют',
      icon: '💱',
      externalLink: 'https://www.myswap.xyz/'
    },
    {
      id: '10kswap',
      title: '10kSwap',
      description: 'Быстрый и эффективный DEX для частых операций',
      icon: '💫',
      externalLink: 'https://10kswap.com/swap'
    }
  ];

  const handleCardClick = (card) => {
    if (card.externalLink) {
      window.open(card.externalLink, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="exchangers-page-container">
      <StarryNight />
      
      <button className="back-button" onClick={() => navigate('/learn/useful-links')}>
        <div className="button-glow"></div>
        <span className="button-icon">←</span>
        <span className="button-text">Назад</span>
      </button>

      <UserCounter />

      <div className="topic-content">
        <h1 className="topic-title">Обменники</h1>
        <div className="ep-cards-grid">
          {cards.map((card, index) => (
            <div 
              key={card.id}
              className="ep-topic-card"
              style={getAnimationStyle(index)}
            >
              <div className="ep-card-content">
                <div className="ep-card-icon">
                  <span>{card.icon}</span>
                </div>
                <h3 className="ep-card-title">{card.title}</h3>
                <p className="ep-card-description">{card.description}</p>
                <button 
                  className="ep-goto-button"
                  onClick={() => handleCardClick(card)}
                >
                  <span className="button-text">Перейти</span>
                  <div className="button-glow"></div>
                </button>
              </div>
              <div className="ep-card-particles">
                {[...Array(5)].map((_, i) => (
                  <div key={i} className="ep-particle"></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExchangersPage;
